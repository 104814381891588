import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classnames from "classnames";
import * as moment from "moment";

import { WebContent as CmsApiWebContent, TemplateApi, WebContentApi, WithId } from "@maxxton/cms-api";
import { DATE_FORMAT, ResultStyleSelector } from "../../../utils/constants";
import { LocalizedDcOptions, WidgetOptions } from "./";
import { RenderVirtualizedList, withVirtualization } from "../../../utils/virtualization/withVirtualization";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { Unit, accoKindById, addressByManagerId, getMxtsEnv } from "../../mxts";
import { dispatchOpenLinkedTabAction, setPageViewEvent } from "../../resultsPanel/resultsPanelUtil";
import { fetchElasticResponse, filterUnitByPrice, generateRandomKey, getHideWidgetClass, isClientLoggedIn, isEqual, sortResults } from "../../../components/utils";
import { getDefaultStayCode, stayPeriodDefById } from "../../mxts/mxts.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getLocalizedContent, isMobileDeviceDetected } from "../../../utils/localizedContent.util";

import { ActionType } from "../../../redux/actions";
import { AmenitiesAction } from "../../../redux/actions/amenitiesAction";
import { ArrayUtil } from "../../../utils/array.util";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { Button } from "reactstrap";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import { MarkerDetails } from "../../page/map/mapWidget.types";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { ResultsPanelAction } from "../../../redux/actions/resultsPanelAction";
import { SmartLink } from "../../../components/SmartLink";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { State } from "../../../redux";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { UserInterfaceState } from "../../../redux/reducers/userInterfaceReducer";
import { cancelable } from "../../../promise";
import { chunk } from "lodash";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getPrioUnitsForReservation } from "../../../sagas/reservationSaga";
import { getStayPeriodDefFilters } from "../../../utils/stayPeriodDefs.util";
import { globalApiContext } from "../../../containers/CmsProvider";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderNoResultsFoundContent } from "../containerWidget.util";
import { renderPageWidgets } from "../../widget";

export interface UnitsearchContainerProps extends UnitsearchContainerBaseProps, UnitsearchContainerStoreProps, UnitsearchContainerDispatchProps {}

interface UnitsearchContainerBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    classNames: string;
    options: WidgetOptions;
    context: CMSProvidedProperties;
    childrenList: JSX.Element[];
    childrenGrid: JSX.Element[];
    childrenListMobile: JSX.Element[];
    childrenGridMobile: JSX.Element[];
    childrenListTablet: JSX.Element[];
    childrenGridTablet: JSX.Element[];
    markerIds?: number[];
    showLoadMoreButton?: boolean;
    warmupState?: UnitsearchContainerState;
    renderVirtualizedList?: RenderVirtualizedList;
    accommodationType?: MXTS.AccommodationType;
}

export interface ResourceObject {
    resortId: number;
}

interface UnitsearchContainerStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    userInterfaceState: UserInterfaceState;
    amenities: MXTS.ElasticFacetV2;
}

interface UnitsearchContainerDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction | ResultsPanelAction>;
}

interface SortedUnit {
    unitId: number;
    nrOfBedrooms: number;
    nrOfBathrooms: number;
    nrOfUnitReviews: number;
    unitRating: number;
    totalCapacity: number | undefined;
    location: { lat: number; lon: number };
    accommodationkindId: number;
    resortId: number;
    specialId: number;
    basePriceInclusive: number | null;
    unitBaseNightPriceInclusive: number | null;
    referencePriceInclusive: number;
    unitNightPriceInclusive: number | null;
    resourceLocation: { lat: number; lon: number };
    baseNightPriceInclusive: number | null;
    nightPriceInclusive: number | null;
    unitBasePriceInclusive?: number | null;
    unitSpecialPriceInclusive?: number | null;
    specialCode?: string[];
    choosableOnInternet?: boolean;
    averageNightPrice?: number;
    averageBaseNightPrice?: number;
    stayPeriodDefId?: number[];
    duration?: number[];
    date?: string;
    minDuration?: number;
}

export interface UnitsearchContainerState {
    disableWidget: boolean;
    units: Unit[];
    mapSynchedUnits: Unit[];
    onMouseOver: MarkerDetails[];
    highlightedMarker: number;
    zoomedUnit: number[];
    showMap: boolean;
    isFetchMoreUnits: boolean;
    fromIndex: number;
    toIndex: number;
    minPrice?: number;
    maxPrice?: number;
    infiniteLoading: boolean;
    resourceId?: number | undefined;
    totalUnits: number;
    deviceType: string;
    tabletView: string;
    mobileView: string;
    unitid?: number | null;
    directSearchInput?: string;
    sortingOption: string;
    webContent?: (CmsApiWebContent & WithId) | null;
    template?: JSX.Element[] | null;
    errorFetchingUnits: string;
    isAllResultFetched: boolean;
    displayGridListMap?: string | undefined;
    shouldComponentDidUpdate: boolean;
    amenities?: NumberMultiSelectOption[];
    specialCodes?: string;
    resortId?: string;
    isEventListnerAdded: boolean;
    regionId?: number;
    amenityCodes?: string[];
    availabilityState: AvailabilityState;
}

export const AMENITIES_COUNT_AGGREGATION: MXTS.Aggregation = {
    count: "UNIT_ID",
    field: "ALL_AMENITIES",
    name: "ALL_AMENITIES_FACET",
    size: 1000,
    type: "FACET",
};

const FETCH_FIRST_CHUNK_UNIT_DATA = 50;
const FETCH_RECURRING_CHUNK_UNIT_DATA = 100;

export function getAverageNightPrice(unit: MXTS.UnitDocument, pricePerNight: any) {
    return pricePerNight?.[unit.unitId.toString()]?.length
        ? ((pricePerNight[unit.unitId.toString()].map((item: any) => +item.nightPriceInclusive).reduce((accumulator: any, currentValue: any) => accumulator + currentValue) /
              pricePerNight[unit.unitId.toString()].length) as any)
        : undefined;
}

export function getAverageBaseNightPrice(unit: MXTS.UnitDocument, pricePerNight: any) {
    return pricePerNight?.[unit.unitId.toString()]?.length
        ? ((pricePerNight[unit.unitId.toString()].map((item: any) => +item.baseNightPriceInclusive).reduce((accumulator: any, currentValue: any) => accumulator + currentValue) /
              pricePerNight[unit.unitId.toString()].length) as any)
        : undefined;
}

// eslint-disable-next-line max-len
export class UnitsearchContainerWidgetBase extends React.Component<UnitsearchContainerProps, UnitsearchContainerState> {
    private iScroll: HTMLDivElement | null;
    private timer: number;
    private buttonDOM: HTMLButtonElement | null;

    private cancelAllPromises?: () => void;
    private controller: AbortController = new AbortController();

    public static async warmupCache(props: UnitsearchContainerProps): Promise<UnitsearchContainerState> {
        return warmupState(props, UnitsearchContainerWidgetBase.defaultState(props), async (stateHandler) => {
            props.dynamicFilter = props.context.reduxStore.store.getState().dynamicFilter;
            props.availabilityState = await AvailabilityUtil.getAvailabilityByDynamicFilter(props.dynamicFilter, undefined, props.context);

            await UnitsearchContainerWidgetBase.fetchAccoUnits(props, stateHandler);
        });
    }

    public static async initDefaultFilter(props: UnitsearchContainerProps): Promise<void> {
        await UnitsearchContainerWidgetBase.populateDynamicFilterWithPreSelectedFilters(props);
    }

    private static defaultState(props: UnitsearchContainerProps): UnitsearchContainerState {
        return {
            disableWidget: true,
            onMouseOver: [],
            highlightedMarker: -1,
            zoomedUnit: [],
            showMap: true,
            isFetchMoreUnits: false,
            fromIndex: 0,
            toIndex: +props.options.defaultNumberOfTypes! || 5,
            infiniteLoading: true,
            units: [],
            mapSynchedUnits: [],
            totalUnits: 0,
            deviceType: "",
            tabletView: "",
            mobileView: "",
            directSearchInput: "",
            sortingOption: props.dynamicFilter.sortingOption || Sort[Sort.highToLowRating],
            errorFetchingUnits: "",
            isAllResultFetched: false,
            shouldComponentDidUpdate: false,
            isEventListnerAdded: true,
            availabilityState: props.availabilityState,
        };
    }
    private intersectionObserver: IntersectionObserver | null = null;
    private sentinelRef: React.RefObject<HTMLDivElement>;
    private prevSentinelRefValue: HTMLDivElement | null = null;

    constructor(props: UnitsearchContainerProps) {
        super(props);
        this.state = {
            ...UnitsearchContainerWidgetBase.defaultState(props),
            ...(props.warmupState || {}),
        };
        this.renderUnitSearchPanel = this.renderUnitSearchPanel.bind(this);
        if (!this.state.isAllResultFetched && props.availabilityState?.availabilityResult) {
            UnitsearchContainerWidgetBase.fetchAccoUnits(props, this);
        }
        this.onIntersection = this.onIntersection.bind(this);
        this.sentinelRef = React.createRef();
    }

    public componentDidMount() {
        const { options, dynamicFilter, userInterfaceState } = this.props;
        const displayGridListMap = userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        this.setState({ disableWidget: !isClientLoggedIn() });
        if (!options.showLoadMoreButton) {
            if (options.enableInlineSlider) {
                if (this.iScroll) {
                    this.iScroll.addEventListener("scroll", this.handleInlineScroll, true);
                }
            }
        }
        if (options.multipleLayouts && screen.width <= 767) {
            this.setState({ deviceType: "mobile", mobileView: "mobile-view", displayGridListMap });
        } else if (options.multipleLayouts && screen.width > 767 && screen.width < 1025) {
            this.setState({ deviceType: "tablet", tabletView: "tablet-view", displayGridListMap });
        }
        if (dynamicFilter.amenities) {
            this.handleAmenities(dynamicFilter.amenities);
        }
    }

    // eslint-disable-next-line max-len
    public componentDidUpdate(prevProps: Readonly<UnitsearchContainerProps>, prevState: Readonly<UnitsearchContainerState>) {
        const {
            options: { enableVirtualization },
            dynamicFilter,
        } = this.props;
        const { displayGridListMap, shouldComponentDidUpdate, isFetchMoreUnits, units, mapSynchedUnits, fromIndex, toIndex, minPrice, maxPrice } = this.state;
        const { mapSynchedUnits: prevMapSynchedUnits, fromIndex: prevFromIndex, toIndex: prevToIndex } = prevState;
        const slicedUnits = mapSynchedUnits?.slice(fromIndex, toIndex) || [];
        const prevSlicedUnits = prevMapSynchedUnits?.slice(prevFromIndex, prevToIndex) || [];
        if (displayGridListMap !== undefined && shouldComponentDidUpdate) {
            const results = document.querySelector(".search-results-wrapper");
            const map = document.querySelector(".map-wrapper");
            const hasChild = document.querySelectorAll(".list-grid-map-view");
            if (hasChild?.length) {
                Array.from(hasChild).forEach((child) => {
                    if (displayGridListMap === "list-view" || displayGridListMap === "grid-view") {
                        if (child?.contains(results as any) === false) {
                            child.classList.add("d-none");
                        } else {
                            child.classList.remove("d-none");
                        }
                    } else if (displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map") {
                        if (child && (child.contains(results as any) || child.contains(map as any))) {
                            child.classList.remove("d-none");
                        }
                    }
                });
            }

            this.setState({
                shouldComponentDidUpdate: false,
                mapSynchedUnits: displayGridListMap === "grid-view" || displayGridListMap === "list-view" ? units : mapSynchedUnits,
            });
        }
        if (isFetchMoreUnits) {
            this.setState({ isFetchMoreUnits: false });
        }

        if (minPrice && maxPrice && !dynamicFilter.minprice && !dynamicFilter.maxprice) {
            this.setMinAndMaxUnitsPrice(false);
        }
        if (this.sentinelRef.current !== this.prevSentinelRefValue && this.sentinelRef.current) {
            this.prevSentinelRefValue = this.sentinelRef.current;
            this.setupObserver();
        }
    }
    private setupObserver() {
        if (this.sentinelRef.current) {
            this.intersectionObserver = new IntersectionObserver(this.onIntersection);
            this.intersectionObserver.observe(this.sentinelRef.current);
        }
    }

    private onIntersection(entries: IntersectionObserverEntry[]) {
        const { isFetchMoreUnits, toIndex, mapSynchedUnits } = this.state;
        if (entries[0]?.isIntersecting && !isFetchMoreUnits && toIndex < mapSynchedUnits.length) {
            this.fetchNextItems();
        }
    }

    public componentWillUnmount() {
        this.controller.abort();
        if (this.iScroll) {
            this.iScroll.removeEventListener("scroll", this.handleInlineScroll, true);
        }
        if (this.intersectionObserver) {
            this.intersectionObserver?.disconnect();
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<UnitsearchContainerProps>) {
        const apiContext = this.props.context || globalApiContext();
        if (this.props.dynamicFilter.onMouseOverOut !== nextProps.dynamicFilter.onMouseOverOut) {
            return;
        }
        if (isEqual(this.props.dynamicFilter, nextProps.dynamicFilter) && isEqual(this.props.availabilityState, nextProps.availabilityState)) {
            return;
        }
        if (this.props.dynamicFilter.updatePriceSlider !== nextProps.dynamicFilter.updatePriceSlider && nextProps.dynamicFilter.updatePriceSlider) {
            return;
        }
        const displayGridListMap = nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        if ((displayGridListMap === "list-view" || displayGridListMap === "grid-view") && !nextProps.availabilityState.fetching && !nextProps.availabilityState.availabilityResult) {
            fetchElasticResponse(apiContext, this.props.dynamicFilter, this.props.dispatchAction, this.props.context.currentLocale.locale);
        }

        if (
            Array.isArray(nextProps.dynamicFilter.resourceid) &&
            nextProps.dynamicFilter.resourceid.length &&
            !nextProps.availabilityState.fetching &&
            !nextProps.availabilityState.availabilityResult &&
            !nextProps.availabilityState.availabilityRequest
        ) {
            fetchElasticResponse(apiContext, this.props.dynamicFilter, this.props.dispatchAction, this.props.context.currentLocale.locale);
        }
        const response = this.props.availabilityState.availabilityResult;
        const nextResponse = nextProps.availabilityState.availabilityResult;
        const nextUnits = nextResponse?.response.units;
        const currentUnits = response?.response.units;
        const env = nextProps.availabilityState.env;
        const nextAmenities = nextResponse?.response?.amenities;
        const { units, zoomedUnit, isAllResultFetched, isEventListnerAdded, mapSynchedUnits } = this.state;
        const { minprice, maxprice, directSearchInput, selectedDirectSearchId, markerIds, sortingOption, amenities } = nextProps.dynamicFilter;
        if (nextResponse && env && !nextProps.availabilityState.fetching) {
            if (nextUnits && !isEqual(nextUnits, currentUnits)) {
                if (nextUnits?.length) {
                    UnitsearchContainerWidgetBase.fetchAccoUnits(nextProps, this);
                } else {
                    this.handleError();
                    return;
                }
            }
        }
        const { context, dispatchAction } = this.props;
        if (this.props.options.enableInlineSlider && mapSynchedUnits.length && isEventListnerAdded) {
            this.setState({ isEventListnerAdded: false }, () => {
                if (this.iScroll) {
                    this.iScroll.addEventListener("scroll", this.handleInlineScroll, true);
                }
            });
        }

        const resourceId = nextProps.options.resourceId;
        if (resourceId && this.state.resourceId !== resourceId) {
            this.setState({ resourceId });
            const dynamicFilter = { ...nextProps.dynamicFilter };
            fetchElasticResponse(context, dynamicFilter, dispatchAction, context.currentLocale.code);
        }

        if ((minprice !== undefined && this.state.minPrice !== minprice) || (maxprice !== undefined && this.state.maxPrice !== maxprice)) {
            this.setState(
                {
                    minPrice: minprice,
                    maxPrice: maxprice,
                },
                () => {
                    if (units?.length) {
                        this.updateAccoUnits(nextProps, this, units, markerIds || zoomedUnit, true, "price");
                    }
                }
            );
        }

        if (markerIds?.every((id) => id !== undefined) && !isEqual(zoomedUnit, markerIds) && isAllResultFetched) {
            this.updateAccoUnits(nextProps, this, units, markerIds, true, "map");
        }

        if (amenities && !isEqual(this.props.dynamicFilter.amenities, amenities)) {
            this.handleAmenities(amenities);
        }

        if (this.state.displayGridListMap !== displayGridListMap) {
            this.setState(
                {
                    displayGridListMap,
                    shouldComponentDidUpdate: true,
                },
                () => {
                    if (displayGridListMap === "list-view" || displayGridListMap === "grid-view") {
                        // To avoid No results message on mobiles
                        if (units?.length) {
                            UnitsearchContainerWidgetBase.fetchAccoUnits(nextProps, this);
                        }
                    }
                }
            );
        }
        if (selectedDirectSearchId !== null && this.state.unitid !== selectedDirectSearchId) {
            this.setState({ unitid: selectedDirectSearchId }, () => {
                this.updateAccoUnits(nextProps, this, units, zoomedUnit, true);
            });
        }
        if (directSearchInput !== undefined && this.state.directSearchInput !== directSearchInput) {
            this.setState({ directSearchInput }, () => {
                this.updateAccoUnits(nextProps, this, units, zoomedUnit, true);
            });
        }

        if (sortingOption && this.state.sortingOption !== sortingOption) {
            // To avoid repetitive sorting when the same sort option is selected
            this.handleSortingSelect(sortingOption, mapSynchedUnits);
        }
        if (nextProps.options.multipleLayouts && screen.width <= 767) {
            this.setState({ deviceType: "mobile", mobileView: "mobile-view", displayGridListMap: nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType });
        } else if (nextProps.options.multipleLayouts && screen.width > 767 && screen.width < 1025) {
            this.setState({ deviceType: "tablet", tabletView: "tablet-view", displayGridListMap: nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType });
        }
        if (nextAmenities && !isEqual(nextAmenities, this.props.amenities)) {
            this.updateAmenities(nextAmenities);
        }
    }

    public shouldComponentUpdate(nextProps: UnitsearchContainerProps, nextState: UnitsearchContainerState) {
        // eslint-disable-next-line max-len
        if (!isEqual(this.props.availabilityState, nextProps.availabilityState)) {
            return false;
        }
        return (
            this.props.availabilityState.fetching !== nextProps.availabilityState.fetching ||
            !isEqual(this.state.mapSynchedUnits, nextState.mapSynchedUnits) ||
            this.state.isAllResultFetched !== nextState.isAllResultFetched ||
            this.props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType !== nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType ||
            (this.state.isFetchMoreUnits !== nextState.isFetchMoreUnits && this.state.toIndex !== nextState.toIndex)
        );
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { childrenList, childrenGrid, childrenListMobile, childrenGridMobile, childrenListTablet, childrenGridTablet, options, context, userInterfaceState, renderVirtualizedList } = this.props;
        const { currentLocale, site } = context;
        const { units, mapSynchedUnits, isFetchMoreUnits, fromIndex, toIndex, infiniteLoading, totalUnits, deviceType, errorFetchingUnits, isAllResultFetched } = this.state;
        const resultLayoutDisplayType = userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        const slicedUnits = mapSynchedUnits?.slice(fromIndex, toIndex) || [];
        const loader: JSX.Element = options.resultsPanelGrid ? (
            <Loader type="typeSearchContainerGrid" views="type-search-container-grid" />
        ) : (
            <Loader type="typeSearchContainerList" views="type-search-container-list" />
        );

        const loadMore = (
            <div>
                {options.showLoadMoreButton &&
                units &&
                (childrenGrid.length || childrenList.length || childrenListMobile.length || childrenGridMobile.length || childrenListTablet.length || childrenGridTablet.length) &&
                toIndex < mapSynchedUnits.length ? (
                    <div className="panel text-center">
                        <Button
                            onClick={this.fetchNextItems}
                            className="button button--l  button--secondary book-btn"
                            innerRef={(buttonDOM: any) => {
                                this.buttonDOM = buttonDOM;
                            }}
                        >
                            <FontAwesome name="spinner" className={classnames("searchfacet-progress", isFetchMoreUnits ? "in-progress" : "no-progress")} />
                            {" " + getI18nLocaleString(namespaceList.widgetTypeSearch, "fetchNextItems", currentLocale, site)}
                            <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                        </Button>
                    </div>
                ) : null}
                {!options.showLoadMoreButton && infiniteLoading ? loader : ""}
            </div>
        );
        if (hideWidget === null) {
            return null;
        }
        let displayUnitContainer = "";
        let displayUnitChild = "";
        let childrenArray: JSX.Element[] = [];
        const filterRegExp = new RegExp("\\$filteredCount");
        const totalRegExp = new RegExp("\\$totalCount");
        if (resultLayoutDisplayType === "grid-view" || resultLayoutDisplayType === "grid-view-map" || (resultLayoutDisplayType === undefined && options.resultsPanelGrid)) {
            childrenArray = this.props.childrenGrid;
            displayUnitContainer = "row";
            // eslint-disable-next-line max-len
            displayUnitChild = `${options.columns ? "col-sm-" + options.columns : ""} ${options.columnsResp ? "col-" + options.columnsResp : "col"} ${
                options.columnsTab ? "col-md-" + options.columnsTab : ""
            } ${options.columnsDesk ? "col-lg-" + options.columnsDesk : ""}`;
        } else if (deviceType === "mobile") {
            if (resultLayoutDisplayType === undefined && options.resultsPanelGrid) {
                childrenArray = this.props.childrenGridMobile;
            } else {
                childrenArray = this.props.childrenListMobile;
            }
        } else if (deviceType === "tablet") {
            if (resultLayoutDisplayType === undefined && options.resultsPanelGrid) {
                childrenArray = this.props.childrenGridTablet;
            } else {
                childrenArray = this.props.childrenListTablet;
            }
        } else {
            childrenArray = this.props.childrenList;
        }
        if (childrenArray.length === 0) {
            childrenArray = this.props.childrenList;
        }
        if (resultLayoutDisplayType === "map-view") {
            return null;
        }
        const resultLayoutLoader = (
            <div className={`search-results-wrap ${hideWidget} unit-search`}>
                <div
                    // eslint-disable-next-line max-len
                    className={`search-results-wrapper ${resultLayoutDisplayType} ${displayUnitContainer}`}
                    style={{ marginTop: "55px", left: "18px" }}
                >
                    <div
                        key="load-0"
                        // eslint-disable-next-line max-len
                        className={`search-result mb-3 ${
                            this.state.mobileView ? "result-" + this.state.mobileView : this.state.tabletView ? "result-" + this.state.tabletView : ""
                        } ${displayUnitChild}`}
                    >
                        <Loader views={resultLayoutDisplayType} />
                    </div>
                    <div
                        key="load-1"
                        // eslint-disable-next-line max-len
                        className={`search-result mb-3 ${
                            this.state.mobileView ? "result-" + this.state.mobileView : this.state.tabletView ? "result-" + this.state.tabletView : ""
                        } ${displayUnitChild}`}
                    >
                        <Loader views={resultLayoutDisplayType} />
                    </div>
                    <div
                        key="load-2"
                        // eslint-disable-next-line max-len
                        className={`search-result mb-3 ${
                            this.state.mobileView ? "result-" + this.state.mobileView : this.state.tabletView ? "result-" + this.state.tabletView : ""
                        } ${displayUnitChild}`}
                    >
                        <Loader views={resultLayoutDisplayType} />
                    </div>
                </div>
            </div>
        );
        if (isServerSide() || this.props.availabilityState.fetching || !isAllResultFetched) {
            return resultLayoutDisplayType ? resultLayoutLoader : loader;
        } else if (units?.length) {
            if (mapSynchedUnits.length) {
                let resultCount = "";
                const filteredIndex = totalUnits ? (options.showTotalResultCount ? totalUnits : toIndex < totalUnits ? toIndex : totalUnits) : toIndex;

                if (mapSynchedUnits && (!options.resultStyleSelector || options.resultStyleSelector === ResultStyleSelector.DEFAULT)) {
                    resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValue", currentLocale, site)
                        .replace(filterRegExp, filteredIndex.toString())
                        .replace(totalRegExp, (totalUnits || "").toString());
                } else if (options.resultStyleSelector === ResultStyleSelector.SHOWING_X_RESULT) {
                    resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle1", currentLocale, site).replace(filterRegExp, filteredIndex.toString());
                } else {
                    resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle2", currentLocale, site).replace(filterRegExp, filteredIndex.toString());
                }
                return (
                    <ErrorBoundary>
                        <div
                            className={`search-results-wrap ${hideWidget} unit-search`}
                            ref={(input) => {
                                this.iScroll = input;
                            }}
                        >
                            {options?.resultStyleSelector !== ResultStyleSelector.DISPLAY_STYLE_HIDE && (
                                <div className="result-count">
                                    {resultCount}
                                    {totalUnits === 0 && <FontAwesome name="spinner" className={classnames("searchfacet-progress", "in-progress")} />}
                                </div>
                            )}
                            <div
                                // eslint-disable-next-line max-len
                                className={`search-results-wrapper ${resultLayoutDisplayType} ${displayUnitContainer}`}
                            >
                                {childrenArray.length && options.enableVirtualization && renderVirtualizedList
                                    ? renderVirtualizedList(slicedUnits, this.renderUnitSearchPanel, { childrenArray, displayType: displayUnitChild })
                                    : this.renderUnvirtualizedList({ childrenArray, slicedUnits, displayUnitChild })}
                            </div>
                            {loadMore}
                        </div>
                        {!options.showLoadMoreButton && <div ref={this.sentinelRef}>{toIndex < mapSynchedUnits.length && isFetchMoreUnits && loader}</div>}
                    </ErrorBoundary>
                );
            }
            return (
                <div className="unit-search no-type-esults">
                    <h4 className="no-type-results-header">{getI18nLocaleString(namespaceList.widgetMap, "tryAgain", currentLocale, site)}</h4>
                    <p className="no-type-results-body">{getI18nLocaleString(namespaceList.widgetMap, "noResults", currentLocale, site)}</p>
                </div>
            );
        } else if (errorFetchingUnits) {
            return <div className={`${hideWidget}`}>{renderNoResultsFoundContent({ noResultsFoundWebContent: this.state.webContent, noResultsFoundTemplate: this.state.template, context })}</div>;
        }
        return resultLayoutDisplayType ? resultLayoutLoader : loader;
    }

    private renderUnitSearchPanel({
        childrenArray,
        elementsArray: slicedUnits,
        displayUnitChild,
        index,
    }: {
        childrenArray: JSX.Element[];
        elementsArray: Unit[];
        index: number;
        displayUnitChild: string;
    }) {
        const { options, dynamicFilter, context, dispatchAction } = this.props;
        const { zoomedUnit, amenityCodes } = this.state;
        const unitBookUri = options.unitBookUri;
        const currentLocale = options.localizedOptions?.find((lo) => lo.locale === context.currentLocale.code);
        const unit = slicedUnits[index];
        const rateTypeId = currentLocale?.rateTypes ? currentLocale.rateTypes[0].value : dynamicFilter.rateType?.rateTypeId;
        const childrenWithProps = React.Children.map(childrenArray, (child) =>
            React.cloneElement((child as React.ReactElement<any>).props.children, {
                unit,
                rateTypeId,
                unitBookUri,
                dynamicFilter,
                key: unit?.unitId || generateRandomKey(),
                amenityCodes: amenityCodes || [],
                dispatchOpenLinkedTabAction: dispatchOpenLinkedTabAction({ unitId: unit.unitId, resourceId: unit.resourceId, dispatchAction }),
                useCrpProps: true,
            })
        );
        const bookUrl = UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, undefined, unitBookUri, dynamicFilter, amenityCodes || []);
        const callSetViewEvent = () => {
            setPageViewEvent(context, bookUrl, undefined, unit);
        };
        return options.enableBookingsEngineLink ? (
            <SmartLink href={bookUrl} target="_blank" onClick={callSetViewEvent}>
                <div
                    key={index}
                    onMouseEnter={this.highlightMarker.bind(this, unit.unitId, "zoom-in")}
                    onMouseLeave={this.highlightMarker.bind(this, unit.unitId, "zoom-out")}
                    // eslint-disable-next-line max-len
                    className={`search-result ${this.state.mobileView ? "result-" + this.state.mobileView : this.state.tabletView ? "result-" + this.state.tabletView : ""} ${
                        zoomedUnit?.length && zoomedUnit.indexOf(unit.unitId) > -1 ? "d-block" : "d-none"
                    } ${displayUnitChild}`}
                >
                    {childrenWithProps}
                </div>
            </SmartLink>
        ) : (
            <div
                key={index}
                onMouseEnter={this.highlightMarker.bind(this, unit.unitId, "zoom-in")}
                onMouseLeave={this.highlightMarker.bind(this, unit.unitId, "zoom-out")}
                // eslint-disable-next-line max-len
                className={`search-result ${this.state.mobileView ? "result-" + this.state.mobileView : this.state.tabletView ? "result-" + this.state.tabletView : ""} ${
                    zoomedUnit?.length && zoomedUnit.indexOf(unit.unitId) > -1 ? "d-block" : "d-none"
                } ${displayUnitChild}`}
            >
                {childrenWithProps}
            </div>
        );
    }

    private renderUnvirtualizedList({ childrenArray, slicedUnits, displayUnitChild }: { childrenArray: JSX.Element[]; slicedUnits: Unit[]; displayUnitChild: string }) {
        return slicedUnits.map((unit, index) =>
            this.renderUnitSearchPanel({
                index,
                childrenArray,
                elementsArray: slicedUnits,
                displayUnitChild,
            })
        );
    }

    private static getAction = (filter: dynamicFilterType, payload: DynamicFilter) => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter,
            payload,
        };
        return action;
    };

    private handleInlineScroll = () => {
        if (this.iScroll!.scrollTop + this.iScroll!.clientHeight >= this.iScroll!.scrollHeight) {
            this.fetchNextItems();
            this.setState({ infiniteLoading: false });
        }
    };

    private fetchNextItems = () => {
        if (this.buttonDOM?.blur) {
            this.buttonDOM.blur();
        }
        const { options } = this.props;
        const { toIndex } = this.state;
        const nextFetchCount = +options.nextNumberOfTypes! || 5;
        const newToIndex = toIndex + nextFetchCount;
        this.setState({
            isFetchMoreUnits: true,
            toIndex: newToIndex,
        });
    };

    private highlightMarker(unitId: number, zoomType: string) {
        clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            const { dynamicFilter } = this.props;
            if (zoomType === "zoom-in" && unitId === dynamicFilter.onMouseOverOut) {
                return;
            }
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.onMouseOverOut,
                payload: {
                    onMouseOverOut: zoomType === "zoom-in" ? unitId : undefined,
                },
            };
            this.props.dispatchAction(action);
        }, 500);
    }

    private handleAmenities = async (amenityIds: string[]) => {
        const context = this.props.context;
        const apiCallOptions = await getMxtsEnv(context, context.currentLocale.code);
        if (amenityIds) {
            // Amenity codes are absent in dynamicFilter, fetching them to pass them towards BE
            const amenityCodes: string[] = [];
            if (amenityIds?.length) {
                try {
                    const amenities = await context.mxtsApi.amenities(apiCallOptions, { amenityIds }, undefined, this.controller.signal).then((am) => am.content);
                    if (amenities) {
                        amenities.forEach((element: MXTS.Amenity) => {
                            amenityCodes.push(element.identifier);
                        });
                    }
                } catch (err) {
                    return;
                }
            }
            this.setState({ amenityCodes });
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private static async fetchAccoUnits(props: UnitsearchContainerProps, stateHandler: StateHandler<UnitsearchContainerProps, UnitsearchContainerState>, tthis?: UnitsearchContainerWidgetBase) {
        const context = props.context;
        let response = props.availabilityState.availabilityResult?.response;
        if (!response?.units && props.accommodationType) {
            const dynamicFilterToFetchUnits: DynamicFilter = { ...props.dynamicFilter, resourceid: props.accommodationType.resourceId, shouldFetchUnitsWithPrice: true };
            response = (await AvailabilityUtil.getAvailabilityByDynamicFilter(dynamicFilterToFetchUnits, {}, context)).availabilityResult?.response;
        }
        const env = props.availabilityState.env;
        const stayPeriodDefIds = response?.stayPeriodDefs || [];
        if (response && env) {
            try {
                const sortingOption = props.dynamicFilter.sortingOption || stateHandler.state.sortingOption;
                let sortedUnits: SortedUnit[] = response
                    .units!.map((su: MXTS.UnitDocument) => {
                        const averageBaseNightPrice = su.baseNightPriceInclusive;
                        const averageNightPrice = su.nightPriceInclusive;
                        return {
                            location: su.unitLocation,
                            unitId: su.unitId,
                            nrOfUnitReviews: su.nrOfUnitReviews,
                            unitRating: su.unitRating,
                            nrOfBedrooms: su.nrOfBedrooms,
                            nrOfBathrooms: su.nrOfBathrooms,
                            totalCapacity: su.totalCapacity,
                            accommodationkindId: su.accommodationkindId,
                            resortId: su.resortId,
                            specialId: su.specialId,
                            specialCode: su.specialCode,
                            resourceLocation: su.resourceLocation,
                            basePriceInclusive: su.basePriceInclusive ? Math.round(su.basePriceInclusive) : null,
                            baseNightPriceInclusive: su.baseNightPriceInclusive ? Math.round(su.baseNightPriceInclusive) : null,
                            nightPriceInclusive: su.nightPriceInclusive ? Math.round(su.nightPriceInclusive) : null,
                            unitBaseNightPriceInclusive: su.unitBaseNightPriceInclusive ? Math.round(su.unitBaseNightPriceInclusive) : null,
                            unitNightPriceInclusive: su.unitNightPriceInclusive ? Math.round(su.unitNightPriceInclusive) : null,
                            unitBasePriceInclusive: su.unitBasePriceInclusive ? Math.round(su.unitBasePriceInclusive) : null,
                            unitSpecialPriceInclusive: su.unitSpecialPriceInclusive ? Math.round(su.unitSpecialPriceInclusive) : null,
                            referencePriceInclusive: su.referencePriceInclusive,
                            choosableOnInternet: su.choosableOnInternet,
                            duration: su.duration || [],
                            minDuration: su.minDuration,
                            date: su.arrivalDate || su.date, // Krim has "arrivalDate" for the cheapest fare,
                            // whereas Nighly index clients have "date". eg. Castle
                            stayPeriodDefId: su.stayPeriodDefId, // StayperiodDefName showing for Untis
                            averageNightPrice: averageNightPrice || undefined,
                            averageBaseNightPrice: averageBaseNightPrice || undefined,
                        };
                    })
                    .filter((unit: SortedUnit) => (props.options.choosableOnInternet ? unit.choosableOnInternet : true));
                // [...sortedUnits] is done for cloning the array so that original reference is not disturbed
                if (props.options?.sortUnitsByPriority) {
                    const prioritizedUnits = await getPrioUnitsForReservation(props?.dynamicFilter, false);
                    sortedUnits = sortedUnits?.sort((unit1, unit2) => prioritizedUnits?.indexOf(unit1.unitId) - prioritizedUnits?.indexOf(unit2.unitId));
                } else {
                    sortedUnits = sortResults([...sortedUnits], sortingOption);
                }
                const promises = env
                    ? [
                          context.mxtsApi.resorts(env, {}, undefined, tthis?.controller.signal).then((result) => result.content) as any,
                          context.mxtsApi.stayPeriodDefs(env, { stayPeriodDefIds }, undefined, tthis?.controller.signal).then((result) => result.content) as any,
                          context.mxtsApi.accommodationkinds(env, { sort: "priority", size: 999 }, undefined, tthis?.controller.signal).then((result) => result.content) as any,
                      ]
                    : [];
                tthis?.cancelAllPromises?.();
                const [allPromises, cancelAllPromises] = cancelable(Promise.all(promises));
                if (tthis) {
                    tthis.cancelAllPromises = cancelAllPromises;
                }
                // eslint-disable-next-line max-lines-per-function
                const [resorts, stayPeriodDefs, accoKinds] = await allPromises;
                // fetching units for single resourceid
                if (props.dynamicFilter.resourceid && sortedUnits.length > 0) {
                    const resourceUnitsIds: number[] = sortedUnits.map((unit: any) => unit.unitId);

                    const idChunks = chunk(resourceUnitsIds, 50);
                    const resourceUnitAddresses: MXTS.Address[] = [];
                    let resourcesUnits: MXTS.Unit[] = [];
                    const allObtainedChunks = await Promise.all(
                        idChunks.map((ids) => context.mxtsApi.unitsWithAddress(env, { size: 999, unitIds: ids?.length ? ids : undefined }, undefined, tthis?.controller.signal))
                    );
                    allObtainedChunks.map((singleUnitWithAddress) => {
                        resourceUnitAddresses.push(...singleUnitWithAddress?.addresses);
                        resourcesUnits.push(...singleUnitWithAddress?.data.content);
                    });

                    if (props.dynamicFilter.unitid) {
                        resourceUnitsIds.length = 0;
                        resourcesUnits = resourcesUnits.filter((unit: any) => unit.unitId === props.dynamicFilter.unitid);
                        resourceUnitsIds.push(resourcesUnits[0].unitId);
                    }
                    const units = UnitsearchContainerWidgetBase.getResources(resourcesUnits, sortedUnits, resorts, resourceUnitAddresses || [], accoKinds, stayPeriodDefs);
                    const { minPrice, maxPrice } = this.getMinAndMaxPrice(units, props.dynamicFilter?.priceRangeCriteria);
                    stateHandler.setState(
                        {
                            isAllResultFetched: true,
                            units,
                            mapSynchedUnits: units,
                            totalUnits: units.length,
                            zoomedUnit: resourceUnitsIds,
                            sortingOption: sortingOption as string,
                            minPrice,
                            maxPrice,
                            availabilityState: props.availabilityState,
                            infiniteLoading: false,
                        },
                        () => {
                            tthis?.setMinAndMaxUnitsPrice(false);
                        }
                    );
                    // fetching units for all resourceids
                } else if (sortedUnits.length > 0) {
                    const allUnitsIds: number[] = sortedUnits.map((unit: any) => unit.unitId);
                    let fromIndex = 0;
                    let toIndex = allUnitsIds.length < FETCH_FIRST_CHUNK_UNIT_DATA ? allUnitsIds.length : FETCH_FIRST_CHUNK_UNIT_DATA;
                    const remainingUnitIdsAfterFirstFetch = allUnitsIds.length - FETCH_FIRST_CHUNK_UNIT_DATA;
                    const totalLoop = Math.ceil(remainingUnitIdsAfterFirstFetch / FETCH_RECURRING_CHUNK_UNIT_DATA) + 1;

                    if (allUnitsIds?.length >= toIndex) {
                        const mxtsUnitPromises: Array<Promise<MXTS.WithAddressResult<MXTS.PagedResult<MXTS.Unit>>>> = [];
                        for (let counter = 1; counter <= totalLoop; counter++) {
                            // eslint-disable-next-line max-len
                            mxtsUnitPromises.push(
                                context.mxtsApi.unitsWithAddress(
                                    env,
                                    {
                                        size: counter === 1 ? FETCH_FIRST_CHUNK_UNIT_DATA : FETCH_RECURRING_CHUNK_UNIT_DATA,
                                        unitIds: allUnitsIds?.length > 1 ? allUnitsIds.slice(fromIndex, toIndex) : allUnitsIds,
                                    },
                                    undefined,
                                    tthis?.controller.signal
                                )
                            );
                            fromIndex = toIndex;
                            toIndex = allUnitsIds.length - toIndex >= FETCH_RECURRING_CHUNK_UNIT_DATA ? toIndex + FETCH_RECURRING_CHUNK_UNIT_DATA : toIndex + allUnitsIds.length - toIndex;
                        }
                        const mxtsUnitsWithAddress: Array<MXTS.WithAddressResult<MXTS.PagedResult<MXTS.Unit>>> = await Promise.all(mxtsUnitPromises);
                        const allUnits: MXTS.Unit[] = ArrayUtil.flatten2Dimensions(
                            mxtsUnitsWithAddress.map((unitsAndAddresses: MXTS.WithAddressResult<MXTS.PagedResult<MXTS.Unit>>) => unitsAndAddresses?.data?.content || [])
                        );
                        const allAddresses: MXTS.Address[] = ArrayUtil.flatten2Dimensions(
                            mxtsUnitsWithAddress.map((unitsAndAddresses: MXTS.WithAddressResult<MXTS.PagedResult<MXTS.Unit>>) => unitsAndAddresses?.addresses || [])
                        );
                        const unitsResults = UnitsearchContainerWidgetBase.getResources(allUnits, sortedUnits, resorts, allAddresses, accoKinds, stayPeriodDefs);
                        const filteredUnits: Unit[] = [];
                        if (props.dynamicFilter.selectedDirectSearchId) {
                            const selectedDirectSearchId = props.dynamicFilter.selectedDirectSearchId;
                            filteredUnits.push(...unitsResults.filter((unit: Unit) => unit.unitId === selectedDirectSearchId));
                        }
                        if (props.dynamicFilter.directSearchInput) {
                            const directSearchInput = props.dynamicFilter.directSearchInput;
                            filteredUnits.push(...unitsResults.filter((unit: Unit) => unit.name.toLowerCase().includes(directSearchInput.toLowerCase())));
                        }
                        // If price is set in Price range widget
                        if (props.dynamicFilter.minprice && props.dynamicFilter.maxprice && !props.dynamicFilter.updatePriceSlider) {
                            filteredUnits.push(...unitsResults.filter((unit: any) => filterUnitByPrice(unit, props)));
                        }
                        if (unitsResults.length === 0 || (props.dynamicFilter.directSearchInput && filteredUnits.length === 0)) {
                            stateHandler.setState({ errorFetchingUnits: "error" });
                            await tthis?.errorHandler();
                        }
                        const fetchedUnits = filteredUnits.length ? filteredUnits : unitsResults;
                        const unitIndexes = fetchedUnits.map((unit: Unit) => unit.unitId);
                        const { minPrice, maxPrice } = this.getMinAndMaxPrice(fetchedUnits, props.dynamicFilter?.priceRangeCriteria);
                        stateHandler.setState(
                            {
                                toIndex: +props.options.defaultNumberOfTypes! || 5,
                                units: fetchedUnits,
                                mapSynchedUnits: fetchedUnits,
                                totalUnits: fetchedUnits.length,
                                zoomedUnit: unitIndexes,
                                minPrice,
                                maxPrice,
                                sortingOption: sortingOption as string,
                                isAllResultFetched: true,
                                availabilityState: props.availabilityState,
                                infiniteLoading: false,
                            },
                            () => {
                                tthis?.setMinAndMaxUnitsPrice(false);
                            }
                        );
                    }
                    if (allUnitsIds.length === 0) {
                        await tthis?.handleError();
                    }
                } else {
                    await tthis?.handleError();
                }
            } catch (err) {
                return;
            }
        }
    }

    private static getMinAndMaxPrice(units: any, criteria?: string) {
        const sortedResults = sortResults(JSON.parse(JSON.stringify(units)), Sort[Sort.highToLowPrice], undefined, undefined, criteria);
        const max = criteria && sortedResults[0][criteria];
        const min = criteria && sortedResults[sortedResults.length - 1][criteria];
        const maxPrice = sortedResults?.[0]?.duration?.length
            ? max || sortedResults?.[0]?.basePriceInclusive
            : sortedResults?.[0]?.unitNightPriceInclusive || sortedResults?.[0]?.unitBaseNightPriceInclusive;

        const minPrice = sortedResults?.[sortedResults.length - 1]?.duration?.length
            ? min || sortedResults?.[sortedResults.length - 1]?.basePriceInclusive
            : sortedResults?.[sortedResults.length - 1]?.unitNightPriceInclusive || sortedResults?.[sortedResults.length - 1]?.unitBaseNightPriceInclusive;
        return { minPrice: minPrice ? Math.round(minPrice) : undefined, maxPrice: maxPrice ? Math.round(maxPrice) : undefined };
    }

    private handleError = async () => {
        await this.errorHandler();
        this.setState({
            units: [],
            zoomedUnit: [],
            errorFetchingUnits: "error",
            isAllResultFetched: true,
            totalUnits: 0,
        });
    };

    private updateAmenities(amenities: MXTS.ElasticFacetV2) {
        const action: AmenitiesAction = {
            type: ActionType.AmenitiesFetch,
            payload: amenities,
        };
        this.props.dispatchAction(action);
    }

    private updateAccoUnits = async (
        props: UnitsearchContainerProps,
        stateHandler: StateHandler<UnitsearchContainerProps, UnitsearchContainerState>,
        units: Unit[],
        markerIds: number[],
        isUnitFilter: boolean,
        filterType?: string
    ) => {
        let filteredUnits: Unit[] = units;
        const dynamicMarkerIds = markerIds;
        const { selectedDirectSearchId, directSearchInput, minprice, maxprice } = props.dynamicFilter;
        if (selectedDirectSearchId && isUnitFilter) {
            filteredUnits = units.filter((unit: Unit) => unit.unitId === selectedDirectSearchId);
        }
        if (directSearchInput && isUnitFilter) {
            filteredUnits = units.filter((unit: Unit) => unit.name.toLowerCase().includes(directSearchInput!.toLowerCase()));
        }
        if (units.length === 0 || (directSearchInput && filteredUnits.length === 0)) {
            this.setState({ errorFetchingUnits: "error" });
            await this.errorHandler();
        }
        if (minprice && maxprice && isUnitFilter && filterType === "price") {
            filteredUnits = units?.filter((unit: any) => filterUnitByPrice(unit, props));
        }
        const amenities = stateHandler.state.availabilityState.availabilityResult?.response.amenities;
        if (props.options.showAmenitiesCount && amenities?.length) {
            this.updateAmenities(amenities);
        }
        const mapSynchedUnits = dynamicMarkerIds ? filteredUnits.filter((unit) => dynamicMarkerIds.indexOf(unit.unitId) > -1) : [];
        let minPrice;
        let maxPrice;
        if (filterType !== "price") {
            const price = UnitsearchContainerWidgetBase.getMinAndMaxPrice(mapSynchedUnits, props.dynamicFilter?.priceRangeCriteria);
            minPrice = price.minPrice;
            maxPrice = price.maxPrice;
        } else {
            minPrice = minprice;
            maxPrice = maxprice;
        }
        this.setState({
            mapSynchedUnits,
            totalUnits: mapSynchedUnits.length,
            zoomedUnit: dynamicMarkerIds,
            minPrice,
            maxPrice,
        });
        if (props.dynamicFilter.minprice && props.dynamicFilter.maxprice) {
            // To avoid re-sorting based on price
            this.setMinAndMaxUnitsPrice(isUnitFilter, filterType, props.dynamicFilter, minPrice, maxPrice);
        }
    };

    private static getResources = (
        resourcesUnits: MXTS.Unit[],
        sortedUnits: SortedUnit[],
        resorts: MXTS.Resort[],
        addresses: MXTS.Address[],
        accoKinds: MXTS.AccoKind[],
        stayPeriodDefs: MXTS.StayPeriodDef[] | undefined
    ) => {
        const units: any[] = [];
        if (resourcesUnits) {
            sortedUnits.forEach((unit: SortedUnit) => {
                const foundUnit = resourcesUnits.find((item) => item.unitId === unit.unitId);
                const foundSortedUnit = sortedUnits.find((sortedUnit) => sortedUnit.unitId === unit.unitId);
                const resort: MXTS.Resort | undefined = resorts.find((availableResort: any) => (foundSortedUnit ? availableResort.resortId === foundSortedUnit.resortId : false));
                const address: MXTS.Address | null = !resort ? null : foundUnit ? addressByManagerId(addresses || [], foundUnit.accommodationAddressManagerId) : null;
                const accommodationkind: MXTS.AccoKind | null = foundSortedUnit ? accoKindById(accoKinds || [], foundSortedUnit.accommodationkindId) : null;
                const stayPeriodDef: MXTS.StayPeriodDef | null = stayPeriodDefById(stayPeriodDefs && foundSortedUnit ? stayPeriodDefs : [], foundSortedUnit!.stayPeriodDefId!);
                const location = foundSortedUnit?.location;
                const resourceLocation = foundSortedUnit?.resourceLocation;
                const nrOfBathrooms = foundSortedUnit?.nrOfBathrooms;
                const nrOfBedrooms = foundSortedUnit?.nrOfBedrooms;
                const nrOfUnitReviews = foundSortedUnit?.nrOfUnitReviews;
                const unitRating = foundSortedUnit?.unitRating;
                const totalCapacity = foundSortedUnit?.totalCapacity;
                const basePriceInclusive = foundSortedUnit?.basePriceInclusive;
                const unitBasePriceInclusive = foundSortedUnit?.unitBasePriceInclusive;
                const unitSpecialPriceInclusive = foundSortedUnit?.unitSpecialPriceInclusive;
                const unitBaseNightPriceInclusive = foundSortedUnit?.unitBaseNightPriceInclusive || foundSortedUnit?.baseNightPriceInclusive;
                const unitNightPriceInclusive = foundSortedUnit?.unitNightPriceInclusive || foundSortedUnit?.nightPriceInclusive;
                const referencePriceInclusive = foundSortedUnit?.referencePriceInclusive;
                const specialId = foundSortedUnit?.specialId;
                const averageNightPrice = foundSortedUnit?.averageNightPrice;
                const averageBaseNightPrice = foundSortedUnit?.averageBaseNightPrice;
                units.push({
                    ...foundUnit,
                    basePriceInclusive,
                    unitBasePriceInclusive,
                    unitSpecialPriceInclusive,
                    unitNightPriceInclusive,
                    unitBaseNightPriceInclusive,
                    referencePriceInclusive,
                    accommodationkindName: accommodationkind?.name || "",
                    city: address?.city || "",
                    resortName: resort?.name || "",
                    latitude: location?.lat || resourceLocation?.lat,
                    longitude: location?.lon || resourceLocation?.lon,
                    nrOfUnitReviews,
                    unitRating,
                    nrOfBathrooms,
                    nrOfBedrooms,
                    totalCapacity,
                    specialId,
                    averageNightPrice,
                    averageBaseNightPrice,
                    stayPeriodDefName: stayPeriodDef == null ? "" : stayPeriodDef.name,
                    stayPeriodDefCode: stayPeriodDef == null ? "" : stayPeriodDef.code,
                    duration: foundSortedUnit?.duration || undefined,
                    date: foundSortedUnit?.date || undefined,
                    minDuration: foundSortedUnit?.minDuration || undefined,
                    specialCode: foundSortedUnit?.specialCode,
                });
            });
        }
        return units;
    };

    private handleSortingSelect = (sortingOption: string, mapSynchedUnits: Unit[]) => {
        const sortedMapSynchedUnits: Unit[] = mapSynchedUnits.length > 0 ? sortResults([...mapSynchedUnits], sortingOption) : [];
        this.setState({
            sortingOption,
            mapSynchedUnits: sortedMapSynchedUnits,
        });
    };

    private setMinAndMaxUnitsPrice = (isUnitFilter: boolean, filterType?: string, dynamicFilter?: DynamicFilter, minPrice?: number, maxPrice?: number) => {
        const { dynamicFilter: filter, options } = this.props;
        if (filterType === "price") {
            const updateMinMaxPriceAction: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.updateMinMaxPrice,
                payload: {
                    ...dynamicFilter,
                    updatePriceSlider: false,
                    updateMap: true,
                },
            };
            this.props.dispatchAction(updateMinMaxPriceAction);
        } else {
            const updateMinMaxPriceAction: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.updateMinMaxPrice,
                payload: {
                    minprice: !filter.minprice || filter.minprice === filter.finalmin ? minPrice || this.state.minPrice : filter.minprice,
                    maxprice: !filter.maxprice || filter.maxprice === filter.finalmax ? maxPrice || this.state.maxPrice : filter.maxprice,
                    updatePriceSlider: !isUnitFilter,
                    updateMap: filterType !== "map",
                    // Future improvements might be needed for below condition
                    finalmin: !filterType ? minPrice || this.state.minPrice : filter.finalmin || this.state.minPrice,
                    finalmax: !filterType ? maxPrice || this.state.maxPrice : filter.finalmax || this.state.maxPrice,
                },
            };
            if (!options.disableDefaultMinMaxPrice) {
                this.props.dispatchAction(updateMinMaxPriceAction);
            }
        }
    };

    private async errorHandler() {
        const { alerts, currentLocale, site } = this.props.context;
        const webContent = await this.getNoDataFoundContent();
        const template = await this.getNoDataFoundTemplate();
        this.setState({ webContent, template });
        if (!this.props.options.disableAlertPopups) {
            this.props.context.alerts.push({
                color: "danger",
                message: getI18nLocaleString(namespaceList.widgetSearchfacet, "noDataFound", currentLocale, site),
            });
        }
    }

    private async getNoDataFoundContent(): Promise<(CmsApiWebContent & WithId) | null> {
        const { webContentId } = this.props.options;
        if (webContentId) {
            return WebContentApi.findById({ id: webContentId });
        }
        return null;
    }
    private async getNoDataFoundTemplate(): Promise<JSX.Element[] | null> {
        const { templateId } = this.props.options;
        if (templateId) {
            const template = await TemplateApi.findById({ id: templateId });
            if (template) {
                return await renderPageWidgets(template.root, this.props.context);
            }
        }
        return null;
    }

    // eslint-disable-next-line max-lines-per-function
    private static async populateDynamicFilterWithPreSelectedFilters(props: UnitsearchContainerProps) {
        const { holidayCode, resortId, regionId, minGuestRating, showAmenitiesCount, minCapacity } = props.options;
        const amenityIds = props.options.amenities?.map((amenity) => amenity.value.toString());
        const specialCodes = props.options.specialCodes?.map((specialCode) => specialCode.value);
        const minArrivalDate = props.options.minArrivalDate ? moment(props.options.minArrivalDate).format(DATE_FORMAT.ELASTIC) : undefined;
        const maxArrivalDate = props.options.maxArrivalDate ? moment(props.options.maxArrivalDate).format(DATE_FORMAT.ELASTIC) : undefined;
        const accoKinds = props.options.accoKinds?.map((accoKind) => +accoKind.value);
        const guestRating = minGuestRating ? +minGuestRating : undefined;
        const {
            context: { currentLocale, site, mxtsApi },
            options: { localizedOptions },
            dispatchAction,
            dynamicFilter: { rateType: { rateTypeId } = {}, distributionChannel: { distributionChannelId } = {}, stay },
        } = props;
        const filter: DynamicFilter = {};
        if (amenityIds?.length) {
            const dynamicAmenities = props.dynamicFilter.amenities?.filter((am) => amenityIds.indexOf(am) === -1) || [];
            filter.amenities = [...dynamicAmenities, ...amenityIds];
        }

        if (specialCodes?.length) {
            filter.specialcode = specialCodes;
        }

        if (resortId) {
            filter.resortids = [+resortId];
        }

        if (regionId) {
            filter.regionIds = [+regionId];
        }

        if (accoKinds?.length) {
            filter.accokindids = accoKinds;
        }

        if (minArrivalDate) {
            filter.minimumArrivalDate = minArrivalDate;
        }
        if (maxArrivalDate) {
            filter.maximumArrivalDate = maxArrivalDate;
        }
        const resourceId = props.options.resourceId;
        if (resourceId) {
            filter.resourceid = +resourceId;
        }
        if (showAmenitiesCount) {
            filter.extraAggregations = [AMENITIES_COUNT_AGGREGATION];
        }
        if (guestRating) {
            filter.guestRating = guestRating;
        }
        if (minCapacity) {
            filter.minCapacity = minCapacity;
        }
        const env = await getMxtsEnv(props.context, props.context.currentLocale.code);
        const { defaultStay, stayFilter } = await getDefaultStayCode(env, mxtsApi, filter, props.options?.defaultStay, stay);
        const stayPeriodDefFilters = await getStayPeriodDefFilters(mxtsApi, { defaultStay, env, dynamicFilter: props.dynamicFilter, holidayCode });
        Object.entries(stayPeriodDefFilters).forEach(([key, value]: ["stayperioddefid" | "stayHolidayPeriodDefId", number]) => {
            filter[key] = value;
        });
        const filterType = dynamicFilterType.addPreselectedFilters;
        const payload: DynamicFilter = { ...props.dynamicFilter, ...filter, ...stayFilter, shouldFetchUnitsWithPrice: true };
        const localizedDcOptions: LocalizedDcOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: localizedOptions || [] });
        // If widget options has DC already selected, dispatching that DC
        const newRateTypeId = localizedDcOptions?.rateTypes?.length ? localizedDcOptions.rateTypes[0].value : rateTypeId;
        if (newRateTypeId && newRateTypeId !== rateTypeId) {
            const rateType = await mxtsApi
                .rates(env, {
                    rateTypeId: newRateTypeId,
                })
                .then((rates) => rates?.content?.[0])
                .catch((error: Error) => {
                    props.context.logger.error(error);
                    return undefined;
                });
            if (rateType) {
                payload.rateType = { rateTypeId: rateType.rateTypeId, code: rateType.code };
            }
        }
        let localizedDcId = localizedDcOptions?.distributionChannelId;
        if (isMobileDeviceDetected() && localizedDcOptions?.mobileDCId) {
            localizedDcId = localizedDcOptions?.mobileDCId;
        }
        const newDistributionChannelId = localizedDcId || distributionChannelId;
        if (newDistributionChannelId && newDistributionChannelId !== distributionChannelId) {
            const distributionChannel = await mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: newDistributionChannelId }]).catch((error: Error) => {
                props.context.logger.error(error);
                return undefined;
            });
            if (distributionChannel) {
                payload.distributionChannel = { distributionChannelId: distributionChannel.distributionChannelId, code: distributionChannel.code };
            }
        }
        const action = UnitsearchContainerWidgetBase.getAction(filterType, payload);
        dispatchAction(action);
    }
}

function mapStateToProps(state: State): UnitsearchContainerStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        userInterfaceState: state.userInterfaceState,
        amenities: state.amenitiesState.amenities,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction | ResultsPanelAction>): UnitsearchContainerDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
const UnitsearchContainer = connect<UnitsearchContainerStoreProps, UnitsearchContainerDispatchProps>(mapStateToProps, mapDispatchToProps)(UnitsearchContainerWidgetBase);

export const UnitsearchContainerWidget = withVirtualization(wrapProps<UnitsearchContainerBaseProps>(UnitsearchContainer));
