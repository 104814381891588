import * as React from "react";

import { debounce } from "lodash";

export interface VirtualizationObserverProps {
    content?: JSX.Element | Array<React.ReactElement<any, string | React.JSXElementConstructor<any>>>;
    measure?: (sizeRef: SizeRef, target: HTMLElement) => void;
}

interface SizeRef {
    width: number;
    height: number;
    offsetWidth: number;
    offsetHeight: number;
}

export const VirtualizationObserver = (props: VirtualizationObserverProps) => {
    const elementRef = React.useRef<HTMLDivElement | null>(null);
    const { content, measure } = props;

    const sizeRef = React.useRef({
        width: -1,
        height: -1,
        offsetWidth: -1,
        offsetHeight: -1,
    });

    React.useEffect(() => {
        const handleResize = () => {
            onInternalResize();
        };
        const debouncedHandleResize = debounce(handleResize, 100);
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                debouncedHandleResize();
            }
        });
        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
        }
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [elementRef]);

    const onInternalResize = React.useCallback(() => {
        const target = elementRef.current;
        if (target) {
            const { width, height } = target.getBoundingClientRect();
            const { offsetWidth, offsetHeight } = target;
            const fixedWidth = Math.floor(width);
            const fixedHeight = Math.floor(height);

            if (sizeRef.current.width !== fixedWidth || sizeRef.current.height !== fixedHeight || sizeRef.current.offsetWidth !== offsetWidth || sizeRef.current.offsetHeight !== offsetHeight) {
                const size = { width: fixedWidth, height: fixedHeight, offsetWidth, offsetHeight };
                sizeRef.current = size;
                if (measure && target) {
                    measure(sizeRef.current, target);
                }
            }
        }
    }, []);

    return (
        <div className="observer-item" ref={elementRef}>
            {content}
        </div>
    );
};
