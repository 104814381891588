import * as React from "react";

import { CellMeasurer, CellMeasurerCache } from "react-virtualized/dist/commonjs/CellMeasurer";
import { ElementsArrayType, ICellMeasurerChildProps, RenderSearchPanel } from "./virtualization.util";

import { AutoSizer } from "react-virtualized/dist/commonjs/AutoSizer";
import { List } from "react-virtualized/dist/commonjs/List";
import { Loader } from "../../components/Loader";
import { VirtualizationObserver } from "./Observe";
import { WindowScroller } from "react-virtualized/dist/commonjs/WindowScroller";

export type RenderVirtualizedList = (
    elementsArray: ElementsArrayType,
    renderSearchPanel: RenderSearchPanel,
    { childrenArray, displayType }: { childrenArray?: JSX.Element[]; displayType?: string }
) => React.ReactNode;

export function withVirtualization<P>(WrappedComponent: React.ComponentType<P> & { injectedProps?: any }): React.ComponentType<P> {
    class withVirtualization extends React.Component<P> {
        private stopIndexRef = React.createRef<number>();
        constructor(props: any) {
            super(props);
            this.renderVirtualizedList = this.renderVirtualizedList.bind(this);
        }
        private cache = new CellMeasurerCache({
            defaultHeight: 400,
            fixedWidth: true,
        });

        private updateStopIndex = ({ stopIndex }: { stopIndex: number }) => {
            this.stopIndexRef = { current: stopIndex };
        };

        private renderVirtualizedList: RenderVirtualizedList = (elementsArray, renderSearchPanel, renderOptions) => (
            <WindowScroller>
                {({ height, scrollTop, isScrolling }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <List
                                width={width}
                                height={height}
                                rowHeight={this.cache.rowHeight}
                                rowRenderer={this.resultRowRenderer(elementsArray, renderSearchPanel, { childrenArray: renderOptions?.childrenArray, displayType: renderOptions?.displayType })}
                                rowCount={elementsArray.length || 1}
                                deferredMeasurementCache={this.cache}
                                overscanRowCount={3}
                                autoHeight
                                scrollTop={scrollTop}
                                isScrolling={isScrolling}
                                onRowsRendered={this.updateStopIndex}
                            />
                        )}
                    </AutoSizer>
                )}
            </WindowScroller>
        );
        private resultRowRenderer = (
            elementsArray: ElementsArrayType,
            renderSearchPanel: RenderSearchPanel,
            { childrenArray, displayType }: { childrenArray?: JSX.Element[]; displayType?: string }
        ) => ({ isScrolling, key, index, style, parent }: any) => (
            <CellMeasurer cache={this.cache} key={key} parent={parent} columnIndex={0} rowIndex={index}>
                {({ measure, registerChild }: ICellMeasurerChildProps) => {
                    let content;
                    let isLoading = false;
                    const stopRef = this.stopIndexRef.current;
                    if (stopRef) {
                        isLoading = (index + 8 < stopRef || index > stopRef + 1) && isScrolling;
                    }

                    if (WrappedComponent.injectedProps?.widgetType === "ReservationContainer" && displayType !== "ACCOMMODATIONS") {
                        content = !isLoading ? (
                            renderSearchPanel(childrenArray, { esReservationResult: elementsArray[index] })
                        ) : (
                            <Loader type="typeSearchContainerList" views="type-search-container-list" />
                        );
                    } else if (WrappedComponent.injectedProps?.widgetType === "ActivitySearchContainer") {
                        content = !isLoading ? renderSearchPanel({ childrenArray, elementsArray, index }) : <Loader type="typeSearchContainerList" views="type-search-container-list" />;
                    } else {
                        content = !isLoading ? renderSearchPanel({ index, elementsArray, childrenArray }) : <Loader type="typeSearchContainerList" views="type-search-container-list" />;
                    }
                    return (
                        // eslint-disable-next-line
                        <div className="virtualized-item" onLoad={measure} ref={registerChild} style={style} key={key}>
                            <VirtualizationObserver content={content} measure={measure} />
                        </div>
                    );
                }}
            </CellMeasurer>
        );

        public render() {
            return <WrappedComponent renderVirtualizedList={this.renderVirtualizedList} {...(this.props as any)} />;
        }
    }
    return withVirtualization;
}
